import React,{useEffect,useRef} from "react";
import "./assets/Process.css";
import icon from "./assets/imgs/icon1w.png"
import gsap from "gsap"
import ScrollTrigger from "gsap/ScrollTrigger";
gsap.registerPlugin(ScrollTrigger);

const Process = ()=>{
    const h1 = useRef(null);
    const button = useRef(null);
    const title = useRef(null);
    const s1 = useRef(null);
    const s2 = useRef(null);
    const s3 = useRef(null);
    const s4 = useRef(null);


    useEffect(()=>{
        gsap.set([h1.current,button.current],{opacity:0,x:-150});
        gsap.set(title.current,{opacity:0,y:-100});
        gsap.set([s1.current,s2.current],{opacity:0,x:-100});
        gsap.set([s3.current,s4.current],{opacity:0,x:100});


        gsap.to([h1.current,button.current],{
            opacity:1,
            x:0,
            duration:1,
            stagger:0.5,
            scrollTrigger:{
                trigger:h1.current,
                start:"top 80%",
                end:"bottom top",
                toggleActions:"play none none reverse",
            },
        })
        gsap.to(title.current,{
            opacity:1,
            y:0,
            duration:1,
            stagger:0.5,
            scrollTrigger:{
                trigger:title.current,
                start:"top 80%",
                end:"bottom top",
                toggleActions:"play none none reverse",
            },
        })
        gsap.to([s1.current,s2.current],{
            opacity:1,
            x:0,
            duration:1,
            stagger:0.5,
            scrollTrigger:{
                trigger:s1.current,
                start:"top 80%",
                end:"bottom top",
                toggleActions:"play none none reverse",
            },
        })
        gsap.to([s3.current,s4.current],{
            opacity:1,
            x:0,
            duration:1,
            stagger:0.5,
            scrollTrigger:{
                trigger:s3.current,
                start:"top 80%",
                end:"bottom top",
                toggleActions:"play none none reverse",
            },
        })
    })

    return(
        <section className="Process" id="Process">
            <div className="BackGroundImg">
            <div className="Tag pTag">
                    <h6>Process</h6>
            </div>
            <div className="Pcontainer">
                <div className="Col1">
                    <h1 ref={h1}>You’re Only 4 Steps from Elevating Your Brand to Its<span> Ultimate Potential</span></h1>
                    <button className="HeroButton Pbutton" ref={button}><img src={icon} alt="" />Book your Free Consultation</button>
                </div>
                <div className="Col2">
                    <p className="Col2H" ref={title}>Go From 0 to launch/Rebrand</p>
                    <div className="Steps">
                        <div className="Col3">
                            <div  ref={s1}>
                                <p className="Step">Step 1</p>
                                <h2>Discovery</h2>
                                <p className="Sdetail">We dive into your business and objectives 
                                exploring your goals to identify your needs</p>
                            </div>
                            <div ref={s2}> 
                                <p className="Step" >Step 2</p>
                                <h2>Strategy & Research</h2>
                                <p className="Sdetail"> We craft a game plan fueled by deep market 
                                insights, competitor analysis, and your 
                                unique goals.</p>
                            </div>
                        </div>
                        <div className="Col4">
                        <div ref={s3}>
                                <p className="Step">Step 3</p>
                                <h2>Development</h2>
                                <p className="Sdetail">We bring your strategy to life, turning vision
                                into reality and elevating your brand</p>
                            </div>
                            <div  ref={s4}>
                                <p className="Step">Step 4</p>
                                <h2>Delivery</h2>
                                <p className="Sdetail">We ship your project and track its 
performance, ensuring it reaches its full 
potential.</p>
                            </div>

                        </div>
                    </div>
                </div>
            </div>
            </div>
        </section>
    )
}

export default Process;