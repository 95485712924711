import React from "react";
import "./assets/Footer.css";
import icon from "./assets/imgs/icon1.png"
import logo from "./assets/imgs/Navlogo.png"
import x from "./assets/imgs/Xlogo.png"
import linkedin from "./assets/imgs/Linkedin.png"
import ig from "./assets/imgs/Instagram.png"
import email from "./assets/imgs/email.png"

const Footer = ()=>{

  const submit = (e) => {
    e.preventDefault();
    const formElement = document.querySelector("form");
    const formData = new FormData(formElement);
  
    fetch("https://script.google.com/macros/s/AKfycby9QioOW54cFniW21V41lBbM48LqzRMZv7murJr3een91Adpsl9NH1mgfktjtBHpG4/exec", {
      method: "POST",
      body: formData,
      mode: 'no-cors',
    })
    .then(() => {
      // Clear the form even though we can't check the response
      formElement.reset();
    })
    .catch(() => {
      // Silently fail since we can't read the error response in 'no-cors' mode
      console.error("Failed to submit the form.");
    });
  };
    return(
        <footer className="Footer" id="Fooeter">
            <div className="upper">
                <div className="part1">
                    <img src={logo} alt="" />
                    <h1>Lets talk about your idea!</h1>
                    <button className="HeroButton FooterB"><img src={icon} alt="" />Book your Free Consultation</button>

                </div>
                <div className="part2">
                    <h1>Get in Touch</h1>
                    <h2>Let’s Discuss Your Next Project</h2>
                    <form className="formContainer" onSubmit={(e)=>submit(e)}>
  <div className="inputRow">
    <div className="inputField">
      <label>Name</label>
      <input name="Name" placeholder="Enter your Name" type="text" />
    </div>
    <div className="inputField">
      <label>Email</label>
      <input name="Email" placeholder="Enter your Mail" type="text" />
    </div>
  </div>
  <div className="inputFieldFull">
    <label>Description</label>
    <textarea name="Message" id="description" placeholder="Enter project details & budgets" rows="5" type="text" />
  </div>
  <button type="submit" className="submitButton">Submit idea</button>
</form>
                </div>
            </div>
            <div className="lower">
                <div className="copy">
                    <p>Elevate Studio™ 2024. All rights reserved.</p>
                </div>
                <div className="Ficons">
                  <a href="">
                    <img src={x} alt="" />
                    </a>
                    <a href="">
                    <img src={ig} alt="" />
                    </a>
                    <a href="">
                    <img src={linkedin} alt=""/>
                    </a>
                    <a href="">
                    <img src={email} alt=""  />
                    </a>
                </div>
            </div>
        </footer>
    )
}

export default Footer;