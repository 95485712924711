import React,{useEffect, useRef} from "react";
import icon from "./assets/imgs/icon1.png"
import "./assets/Hero.css"
import gsap from "gsap"

import {useTypewriter} from 'react-simple-typewriter'

const Hero =()=>{
    const [typeeffect] = useTypewriter({
        words: ['Elevates Retention' , 'Accelerates Growth','Delivers Impressions'],
        loop:{},
        typeSpeed:80,
        deleteSpeed:50,
    })

    const title = useRef(null);
    const sub = useRef(null);
    const button = useRef(null);
    const buttonsub = useRef(null);
    useEffect(()=>{
        gsap.set([title.current,sub.current],{
            opacity:0,
            y:100,
        });
        gsap.set([button.current,buttonsub.current],{
            opacity:0,
            y:200,
        });
        gsap.to([title.current,sub.current,button.current,buttonsub.current],{
            opacity:1,
            y:0,
            duration:0.5,
            stagger:0.3,
            ease:"power3.out",
        })
    },[]);
    return(

        <section className="HeroSection" id="Hero">
            <div className="Title" ref={title}>
                <p>Your Design Partner That<br/><span className="change">{typeeffect}</span></p>
            </div>
            <div className="SubTitle" ref={sub}>
                <h2>Designs and strategies crafted to <span>create lasting impressions</span> from the
                first moment and help you<span> rise above in a crowded marketplace.</span></h2>
            </div>
            <div className="CTA">
                <button className="HeroButton" ref={button}><img src={icon} alt="" />Book your Free Consultation</button>
                <p className="ButonTitle" ref={buttonsub}>Free of Charge. No Obligations.</p>
            </div>
            <div className="VideoContainer">
            </div>
        </section>
    )
}

export default Hero;