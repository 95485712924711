import React, { useEffect, useRef } from "react";
import "./assets/WhyUs.css";
import Logo1 from "./assets/imgs/growth-chart1.png";
import Logo2 from "./assets/imgs/best-customer-experience1.png"
import Logo3 from "./assets/imgs/service1.png"
import gsap from "gsap";
import { ScrollTrigger } from "gsap/ScrollTrigger"
gsap.registerPlugin(ScrollTrigger);
const WhyUs = ()=>{
    const title = useRef(null);
    const img1 = useRef(null);
    const t1 = useRef(null);
    const img2 = useRef(null);
    const t2 = useRef(null);
    const img3 = useRef(null);
    const t3 = useRef(null);


    useEffect(()=>{
        gsap.set(title.current,{opacity:0,y:150});
        gsap.set([img1.current,img2.current,img3.current],{opacity:0,x:-250});
        gsap.set([t1.current,t2.current,t3.current],{opacity:0,x:250});
        

        gsap.to(title.current,{
            opacity:1,
            y:0,
            duration:1,
            scrollTrigger:{
                trigger:title.current,
                start:"top 100%",
                end:"bottom top",
                toggleActions: "play none none reverse",
            },
        })
        
        gsap.to([img1.current,img2.current,img3.current],{
            opacity:1,
            x:0,
            duration:1,
            stagger:0.5,
            scrollTrigger:{
                trigger:img1.current,
                start:"top 100%",
                end:"bottom top",
                toggleActions: "play none none reverse",
            },
        })
        gsap.to([t1.current,t2.current,t3.current],{
            opacity:2,
            x:0,
            duration:1,
            stagger:0.5,
            scrollTrigger:{
                trigger:t1.current,
                start:"top 100%",
                end:"bottom top",
                toggleActions: "play none none reverse",
            },
        })



    })

    return(
        <section className="WhyUsSection" id="WhyUs">
            <div className="Tag">
                    <h6>Why Us</h6>
            </div>
            <div className="WhyTitle" ref={title}>
                <h1>Elevate Your Brand With Our<br/><span>All-in-One</span> Creative Team</h1>
            </div>
            <div className="Container">
                <div className="WhyCard">
                    <img src={Logo1} alt="" ref={img1} />
                    <h2 ref={t1}>Results-Oriented Design 
                    Strategies for Business Growth
                    </h2>
                </div>
                <div className="WhyCard">
                    <img src={Logo2} alt="" ref={img2}/>
                    <h2 ref={t2}>15+ Years of Combined 
                    Mastery in Design and Motion</h2>
                </div>
                <div className="WhyCard">
                    <img src={Logo3} alt="" ref={img3}/>
                    <h2 ref={t3}>Digital, Motion, and Creative Arts
                    Under One Umbrella</h2>
                </div>
            </div>
        </section>
    )
}

export default WhyUs;