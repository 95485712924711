import React, { useState, useEffect,useRef } from "react";
import "./assets/FAQ.css";
import icon from "./assets/imgs/icon1w.png"
import iconb from "./assets/imgs/icon1.png"
import logo from "./assets/imgs/WordMark2.png"
import gsap from "gsap";
import ScrollTrigger from "gsap/ScrollTrigger";
gsap.registerPlugin(ScrollTrigger);


const FAQ = ()=>{
    const [openIndex, setOpenIndex] = useState(null);
    const toggleFAQ = (index) => {
        setOpenIndex(openIndex === index ? null : index); // Toggle the clicked FAQ
      };

    const Title = useRef(null);
    const card = useRef(null); 

    useEffect(()=>{
        gsap.set(Title.current,{opacity:0,x:-150});
        gsap.set(card.current,{opacity:0,x:400});

        gsap.to(Title.current,{
            opacity:1,
            x:0,
            duration:1,
            stagger:0.5,
            scrollTrigger:{
                trigger:Title.current,
                start:"top 80%",
                end:"bottom top",
                toggleActions:"play none none reverse",
            },
        })

    gsap.to(card.current, {
      opacity: 1,  // Rotation into view (along Y-axis)
      x: 0,          // Move the card to its final position
      duration: 2, // Adjust the duration for smoother animation
      ease: "power3.inout", // Smooth easing
      scrollTrigger: {
        trigger: card.current, // Start the animation when card is in view
        start: "top 80%",      // Adjust trigger position as needed
        once: true,            // Play animation only once
      },
    });

    },[])
    return(
        <section className="FAQ" id="FAQ">
            <div className="FAQcol1">
                <div className="Tag FAQt">
                    <h6>FAQ</h6>
                </div>
                <h1 ref={Title}>Commonly asked questions</h1>
                <div className="FAQcontainer">
                    <div className="Question" onClick={() => toggleFAQ(0)}><h2>Do you offer ongoing support
                    after the project?</h2><span className={`Add ${openIndex === 0 ? 'open' : ''}`}>+</span></div>
                    <p className={`Answer ${openIndex === 0 ? 'open' : ''}`}>Absolutely! We provide complete support for 14 days after the project's completion to address any issues that may arise. Beyond that, we offer retainer options to manage or consult on your brand as you move forward.</p>
                </div>
                <div className="FAQcontainer">
                    <div className="Question" onClick={() => toggleFAQ(1)}><h2>Can you work with my existing
                    branding?</h2><span className={`Add ${openIndex === 1 ? 'open' : ''}`}>+</span></div>
                    <p className={`Answer ${openIndex === 1 ? 'open' : ''}`}>Definitely! We excel at rebrands and are more than capable of working within your existing brand guidelines. Whether you need social media management or any other service, we’ll ensure consistency with your established identity.</p>
                </div>
                <div className="FAQcontainer">
                    <div className="Question" onClick={() => toggleFAQ(2)}><h2>How quickly can I expect progress?</h2><span className={`Add ${openIndex === 2 ? 'open' : ''}`}>+</span></div>
                    <p className={`Answer ${openIndex === 2 ? 'open' : ''}`}>We get the ball rolling in less than 48 hours! From there, we’ll keep you in the loop every step of the way, ensuring you’re informed and involved throughout the process.</p>
                </div>
                <div className="FAQcontainer">
                    <div className="Question" onClick={() => toggleFAQ(3)}><h2>Do you charge for additional
                    edits or revisions?</h2><span className={`Add ${openIndex === 3 ? 'open' : ''}`}>+</span></div>
                    <p className={`Answer ${openIndex === 3 ? 'open' : ''}`}>Not at all. We see ourselves as your partners, committed to bringing your vision to life. Your investment is safe with us, as we strive to deliver exactly what you’re looking for—no extra charges for getting it right.</p>
                </div>
            </div>
            <div className="FAQcol2">
                <div className="FAQcard" ref={card}>
                    <img src={logo} alt="" />
                    <h1>Lets Craft the next big Brand at Elevate Studio</h1>
                    <button className="HeroButton Pbutton Fbutton"><img src={icon} alt="" />Book your Free Consultation</button>
                    <p className="ButonTitle">Free of Charge. No Obligations.</p>
                </div>
                <button className="HeroButton Fbutton hidden"><img src={iconb} alt="" />Book your Free Consultation</button>
            </div>
        </section>
    )
}

export default FAQ;